import React, { useState } from "react";
import { default as TerminalComponent } from "terminal-in-react";
import Draggable from "react-draggable";
import EXPERIENCE_TEXT from "../../constants/experience";
import EDUCATION_TEXT from "../../constants/education";
import ABOUT_TEXT from "../../constants/about";
import PROJECTS_TEXT from "../../constants/projects";
import {
  CANCELLABLE_NOT_DRAGABLE_CLASS,
  FILENAMES,
  SOCIAL_LINKS,
  SHOW_TEXT,
  NO_FILE_PASSED_TEXT
} from "../../constants";

function Terminal(props) {
  const { isOpen, closeModal, isMobileScreen = false } = props;
  // Flag for the maximise functionality to work along after drag
  const [flag, setFlag] = useState(0);

  const showFileContent = fileName => {
    switch (fileName) {
      case FILENAMES.EXPERIENCE:
        return EXPERIENCE_TEXT;
      case FILENAMES.EDUCATION:
        return EDUCATION_TEXT;
      case FILENAMES.PROJECTS:
        return PROJECTS_TEXT;
      default:
        return NO_FILE_PASSED_TEXT;
    }
  };

  const resetDrag = () => {
    setFlag(1);
    setTimeout(() => {
      setFlag(0);
    }, 1000);
  };

  const renderTerminal = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
      }}
    >
      <TerminalComponent
        color="white"
        backgroundColor="#333"
        showActions={!isMobileScreen}
        startState={isMobileScreen ? "maximised" : "open"}
        barColor="black"
        prompt="red"
        promptSymbol="akshatnitd >"
        style={{ fontWeight: "bold", fontSize: "1em" }}
        commands={{
          whoami: {
            method: (args, print, runCommand) => {
              print(ABOUT_TEXT);
            }
          },
          education: {
            method: (args, print, runCommand) => {
              runCommand(`cat ${FILENAMES.EDUCATION}`);
            }
          },
          experience: {
            method: (args, print, runCommand) => {
              runCommand(`cat ${FILENAMES.EXPERIENCE}`);
            }
          },
          projects: {
            method: (args, print, runCommand) => {
              runCommand(`cat ${FILENAMES.PROJECTS}`);
            }
          },
          resume: () => {
            window.open("/resume.pdf", "_blank");
          },
          ls: {
            method: (args, print, runCommand) => {
              print(
                `${FILENAMES.EDUCATION} ${FILENAMES.PROJECTS} ${FILENAMES.EXPERIENCE}`
              );
            }
          },
          cat: {
            method: (args, print, runCommand) => {
              print(showFileContent(args._[0]));
            }
          },
          github: () => {
            window.open(SOCIAL_LINKS.GITHUB, "_blank");
          },
          linkedin: () => {
            window.open(SOCIAL_LINKS.LINKEDIN, "_blank");
          }
        }}
        descriptions={{
          whoami: "About myself",
          experience: "Show experience",
          education: "Show education",
          projects: "Show projects",
          ls: "Show list of files",
          cat: "Display file contents (eg: cat filename.txt)",
          github: "Open GitHub profile",
          linkedin: "Open Linkedin profile",
          resume: "Show resume"
        }}
        actionHandlers={{
          handleClose: toggleClose => {
            closeModal();
          },
          handleMaximise: toggleMaximise => {
            resetDrag();
            toggleMaximise();
          }
        }}
        msg={SHOW_TEXT}
      />
    </div>
  );

  if (!isOpen) return null;

  if (isMobileScreen) return renderTerminal();

  return (
    <Draggable
      cancel={`.${CANCELLABLE_NOT_DRAGABLE_CLASS}`}
      position={flag === 0 ? null : { x: 0, y: 0 }}
    >
      {renderTerminal()}
    </Draggable>
  );
}

export default Terminal;
