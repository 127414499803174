import React, { useEffect, useState } from "react";
import Terminal from "./components/Terminal";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { CANCELLABLE_NOT_DRAGABLE_CLASS } from "./constants";
import { isMobileView } from "./utils";
import "./App.css";

function App() {
  const [open, setOpen] = useState(true);
  const isMobileScreen = isMobileView();

  useEffect(() => {
    const element = document.getElementsByTagName("input")[0];
    if (element) element.classList.add(CANCELLABLE_NOT_DRAGABLE_CLASS);
  });

  const closeModal = () => setOpen(false);

  const toggleModal = () => setOpen(!open);

  if (isMobileScreen)
    return (
      <Terminal
        isOpen={open}
        closeModal={closeModal}
        isMobileScreen={isMobileScreen}
      />
    );

  return (
    <div className="App">
      <Header />
      <Terminal isOpen={open} closeModal={closeModal} />
      <Footer toggleModal={toggleModal} />
    </div>
  );
}

export default App;
