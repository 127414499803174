export default `

    __________  __  ___________  ______________  _   __
   / ____/ __  / / / / ____/   |/_  __/  _/ __  / | / /
  / __/ / / / / / / / /   / /| | / /  / // / / /  |/ /
 / /___/ /_/ / /_/ / /___/ ___ |/ / _/ // /_/ / /|  /
/_____/_____/ ____/ ____/_/  |_/_/ /___/ ____/_/ |_/


NIT, DURGAPUR
=============

B.TECH | I.T.
Graduated on May 2018
Durgapur, WB
CGPA: 8.51

DELHI PUBLIC SCHOOL
===================

XII | CBSE
Passed in March 2014
Siliguri, WB
PERCENTAGE: 95%

X | CBSE
Passed in March 2012
Siliguri, WB
CGPA: 9.8
`;
