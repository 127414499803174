import React, { Component } from "react";
import moment from "moment";
import "./Header.css";
import { HEADER_CONFIG } from "../../constants";

class Header extends Component {
  state = {
    time: moment().format("dddd, MMMM Do YYYY, h:mm a")
  };

  updateClock = () => {
    this.setState({
      time: moment().format("dddd, MMMM Do YYYY, h:mm a")
    });
  };

  componentDidMount() {
    setInterval(this.updateClock, 1000);
  }

  render() {
    return (
      <header>
        <ul className="left">
          <li>
            <img
              alt="AG"
              height="20px"
              src={require("../../assets/images/icons/akshat.png")}
            />
          </li>
        </ul>
        <ul className="right">
          {HEADER_CONFIG.map(eachItem => {
            const { iconSuffix, name, hrefLink } = eachItem;
            return (
              <li key={name}>
                <a href={hrefLink} target="_blank" rel="noopener noreferrer">
                  <i className={`fa fa-${iconSuffix}`}></i>
                </a>
              </li>
            );
          })}
          <li>{this.state.time}</li>
          <li>Akshat Garg</li>
        </ul>
      </header>
    );
  }
}

export default Header;
