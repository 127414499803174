export default `

    ___    ____  ____  __  ________   __  _________
   /   |  / __ )/ __  / / / /_  __/  /  |/  / ____/
  / /| | / __  / / / / / / / / /    / /|_/ / __/
 / ___ |/ /_/ / /_/ / /_/ / / /    / /  / / /___
/_/  |_/_____/ ____/ ____/ /_/    /_/  /_/_____/


Hi! I am Akshat. I am obsessed with making new things and even more, in making things better. I am always open to new ideas with the zeal and enthusiasm to learn new stuff. Fun fact: My TODO list is never ending :P
`;
