export default `

    ____  ____  ____      _________________________
   / __  / __  / __ |    / / ____/ ____/_  __/ ___/
  / /_/ / /_/ / / / /_  / / __/ / /     / /  |__ |
 / ____/ _, _/ /_/ / /_/ / /___/ /___  / /  ___/ /
/_/   /_/ |_| ____/ ____/_____/ ____/ /_/  /____/


IMDB PORTAL
===========

++ PYTHON | BS4 | SCRAPING ++
-----------------------------

A simple python utility script which assists in getting movies’ information,ratings and
renaming each movie directory in the desktop by appending IMDB rating and year of
release to each folder’s name. Link : https://github.com/akshatnitd/imdb_portal

TABS SHARE
==========

++ HTML | CSS | JAVASCRIPT ++
-----------------------------

A chrome extension for saving, sharing and reloading the browsertabs.
Link : htttps://github.com/akshatnitd/tabs-share


Contribution to various Open Source Organisations namely FOSSASIA , Uber Open
Source , Mozilla , NITDgpOS , Coala , ReactJs , etc.

`;
