export default `

    _______  __ ____  __________  ___________   ______________
   / ____/ |/ // __  / ____/ __  /  _/ ____/ | / / ____/ ____/
  / __/  |   // /_/ / __/ / /_/ // // __/ /  |/ / /   / __/
 / /___ /   |/ ____/ /___/ _, _// // /___/ /|  / /___/ /___
/_____//_/|_/_/   /_____/_/ |_/___/_____/_/ |_/ ____/_____/


UBER
====

++ SOFTWARE ENGINEER II | MARCH 2020 - PRESENT ++
--------------------------------------------------

• Leading the front-end related projects and helping ship scalable products to
combat fraud and save dollars for Uber.

++ WEB ENGINEER | MARCH 2019 - MARCH 2020 ++
---------------------------------------------

• Working with the Risk team and responsible forthe development of projects
consisting of multiple web-apps.
• Exposure: FusionJs, React/Redux, GraphQL/Apollo, gRPC, RPC, BaseUI, Thrift.

TOPPR
=====

++ SOFTWARE ENGINEER | JUNE 2018 - FEB 2019 ++
-----------------------------------------------

• Worked with the front-end development team and responsible forthe
development of projects consisting of multiple web-apps and hybrid native apps.
• The products include a learning web-app consisting modules for different
subjects, a Websocket based chat plaform for students and tutors for clearing
doubts, apart from the otherin-house apps.
• Exposure: React, Redux, React Native, Websockets, Nunjacks, Express, PM2

FOSSASIA
========

++ PROJECT MAINTAINER | 2018 - CURRENT ++
-----------------------------------------

• Responsible for maintaining the SUSI.AI project and various other projects
under FOSSASIA.
• Mentored in various programmes organised by Google like the Google Summer
of Code in 2019 and Google Code-In in 2017, 2018, 2019 editions, apart from
other competitions organised by FOSSASIA like Codeheat, FOSSASIA
Internship, etc.

GOOGLE SUMMER OF CODE
=====================

++ STUDENT DEVELOPER @ FOSSASIA | APRIL 2018 - AUG 2018 ++
-----------------------------------------------------------

• Worked on SUSI.AI , an intelligent open source personal assistant and was
responsible for developing a analytics platform for SUSI.AI
• Worked on Web client , skills CMS and server . Written over 10k+ lines of
code and made over 100 pullrequests in multiple SUSI.AI sub-repositories.
• Exposure: React, Redux, Material-UI design, JAVA Servlets, Jetty Server

PREGBUDDY
=========

++ FULL STACK DEVELOPER INTERN | MAY 2017 – JUNE 2017 ++
--------------------------------------------------------

• Created multiple APIs and automated product emails and push notifications.
• Optimized the Progressive Web App(PWA) to achieve a LightHouse score of
100/100 , along with improvement in first load time bundle size.
• Exposure: Flask, AWS EC2, AWS SES, Firebase Realtime DB & Cloud Functions

++ FRONT-END DEVELOPER INTERN | DEC 2016 – MARCH 2017 ++
---------------------------------------------------------

• Single-handedly built a PWA using ReactJS from scratch to production in < 30
days as per Google guidelines. Web-App link: https://app.pregbuddy.com
• Exposure: ReactJs, Material - UI, AJAX, Firebase Cloud Messaging, AWS S3
`;
