/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./Footer.css";
import { FOOTER_CONFIG } from "../../constants";

const Footer = ({ toggleModal }) => {
  const jump = event => {
    event.target.classList.remove("jump");
    event.target.classList.add("jump");
  };

  return (
    <footer>
      <ul>
        <li data-title="Finder">
          <img
            onClick={e => {
              toggleModal();
              jump(e);
            }}
            src={require("../../assets/images/icons/terminal.png")}
          />
        </li>
        <li data-title="Chrome">
          <a
            href="https://www.google.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              onClick={jump}
              src={require("../../assets/images/icons/chrome.png")}
            />
          </a>
        </li>
        {FOOTER_CONFIG.map(eachItem => {
          const { name, iconLink, hrefLink } = eachItem;
          return (
            <li key={name} data-title={name}>
              <a href={hrefLink} target="_blank" rel="noopener noreferrer">
                <img
                  onClick={jump}
                  src={require(`../../assets/images/icons/${iconLink}`)}
                />
              </a>
            </li>
          );
        })}
      </ul>
    </footer>
  );
};

export default Footer;
