export const CANCELLABLE_NOT_DRAGABLE_CLASS = "not-draggable";
export const FILENAMES = {
  EXPERIENCE: "experience.txt",
  EDUCATION: "education.txt",
  PROJECTS: "projects.txt"
};

export const SOCIAL_LINKS = {
  LINKEDIN: "https://linkedin.com/in/akshatnitd",
  FB: "https://fb.com/akshatnitd",
  GITHUB: "https://www.github.com/akshatnitd",
  WORDPRESS_BLOG: "https://aspirewithmeblog.wordpress.com/",
  TWITTER: "https://twitter.com/@tweet_akshat"
};

export const FOOTER_CONFIG = [
  {
    name: "Blog",
    iconLink: "wordpress.png",
    hrefLink: SOCIAL_LINKS.WORDPRESS_BLOG
  },
  {
    name: "GitHub",
    iconLink: "github.png",
    hrefLink: SOCIAL_LINKS.GITHUB
  },
  {
    name: "LinkedIn",
    iconLink: "linkedin.png",
    hrefLink: SOCIAL_LINKS.LINKEDIN
  },
  {
    name: "Facebook",
    iconLink: "fb.png",
    hrefLink: SOCIAL_LINKS.FB
  },
  {
    name: "Twitter",
    iconLink: "twitter.png",
    hrefLink: SOCIAL_LINKS.TWITTER
  }
];

export const HEADER_CONFIG = [
  {
    name: "Blog",
    iconSuffix: "wordpress",
    hrefLink: SOCIAL_LINKS.WORDPRESS_BLOG
  },
  {
    name: "GitHub",
    iconSuffix: "github",
    hrefLink: SOCIAL_LINKS.GITHUB
  },
  {
    name: "LinkedIn",
    iconSuffix: "linkedin",
    hrefLink: SOCIAL_LINKS.LINKEDIN
  },
  {
    name: "Facebook",
    iconSuffix: "facebook",
    hrefLink: SOCIAL_LINKS.FB
  },
  {
    name: "Twitter",
    iconSuffix: "twitter",
    hrefLink: SOCIAL_LINKS.TWITTER
  }
];

export const SHOW_TEXT = "Type 'help' to show the list of available commands";

export const NO_FILE_PASSED_TEXT =
  "Please enter a filename to view. (eg: cat filename.txt)";
